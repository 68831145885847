body {
  background: #34515e;
  color: #ffffff;
}

.App {
  text-align: center;
  margin: 0 5px;
}

.App-logo {
  height: 40vmin;
}

.dim {
  opacity: 0.7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.c-logo {
  width: 200px;
  text-align: center;
}

.c-header {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-header__name {
  margin: 16px 0px;
  display: block;
}

.c-header__social {
  margin: 16px 0px;
  padding-left: 0;
  display: block;
  list-style-type: none;
}

.c-header__social__item {
  display: inline;
  list-style-type: none;
  margin-right: 5px;
}

.c-header__social__item:hover {
  transform: rotate(169deg);
}

.c-grid__item {
  width: 100%;
  margin-bottom: 5px;
}

/* .c-grid__item:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
} */

.c-image-container--hidden {
  display: none;
}

.c-overlay {
  width: 100%;
  height: 100%;
  border: 2px solid red;
  position: absolute;
  top: 0;
  left: 0;
}

.c-overlay--hidden {
  display: hidden;
}

.c-footer {
  margin: 10px 10px;
}

@media screen and (min-width: 375px) {
  .App {
    margin: 0 5px;
  }
  .c-header {
    margin: 20px 0px;
  }
}

@media screen and (min-width: 508px) {
  .App {
    margin: 0px 5%;
    padding: 0;
  }

  .c-header__social__item {
    margin-right: 5px;
  }

  .c-grid__item {
    width: 49%;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 550px) {
  .c-header__social {
    display: inline-block;
  }

  .c-header {
    flex-direction: row;
  }

  .c-header__name {
    margin: 16px 0px;
    width: 245px;
    height: 100px;
    display: inline-block;
  }

  .c-logo {
    width: 200px;
    text-align: left;
  }
}

@media screen and (min-width: 1100px) {
  .c-grid__item {
    width: 33%;
    margin-bottom: 5px;
  }
}
